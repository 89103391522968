/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import tw from "tailwind-styled-components";
import { useLocalStorage } from "../../hooks";

export function MobileAppDownloadBanner(): JSX.Element | null {
  const [show, setShow] = useLocalStorage("showAppDownloadBanner", true);
  const defaultLocale = "fr";
  const [language, setLanguage] = useState(defaultLocale);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  return show && isMobile ? (
    <div className="flex flex-row justify-between bg-black px-4 py-3 lg:hidden w-screen absolute top-0 z-50">
      <a
        href={process.env.NEXT_PUBLIC_DOWNLOAD_APP_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <TitleContainer>
          <Title>
            {language === "fr"
              ? "Telecharger notre application"
              : "Get the app"}
          </Title>
        </TitleContainer>
        <Description>
          {language === "fr" ? (
            <>
              Allocab est le 1er réseau de VTC en France !<br />
              Commandez en toute sécurité
            </>
          ) : (
            <>
              Allocab is the No.1 ride-hailing service in France !<br />
              Book your ride with total confidence.
            </>
          )}
        </Description>
      </a>
      <Close
        onMouseDown={(e: any) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          setShow(false);
        }}
      >
        {"X"}
      </Close>
    </div>
  ) : null;
}

const TitleContainer = tw.div`
col-span-1
flex flex-row content-center justify-between
`;

const Title = tw.div`
text-sm
font-bold
text-white
underline
`;

const Close = tw.div`
text-base
font-bold
text-white
`;

const Description = tw.div`
text-white
text-xs
`;
