/* eslint-disable indent */
import tw from "tailwind-styled-components";
import { Button, HeaderSection, Image } from "../../../components";
import { PageVariables } from "../../../services/pageVariables";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { textCompiler } from "../../../services/textCompiler";
import { ImageBannerSectionType } from "../../../types";

interface ImageBannerSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: ImageBannerSectionType;
}

export function ImageBannerSection(
  props: ImageBannerSectionProps
): JSX.Element {
  const { data, variables, isFullScreen } = props;

  return (
    <Main
      $isFullScreen={isFullScreen}
      $isImageLeft={data.imageLeft}
      style={{ backgroundColor: data.bgColor }}
    >
      <LeftCol>
        {data.image.data && (
          <StyledImg
            src={getStrapiMediaUrl(data.image.data.attributes)}
            alt={data.image.data.attributes.name}
          />
        )}
      </LeftCol>
      <RightCol>
        <HeaderSection
          title={textCompiler(data.title, variables)}
          titleSize={data.titleSize}
          textSize={data.textSize}
          text={textCompiler(data.text, variables)}
        />
        {data.cta && (
          <ButtonStyled
            styled="w-fit"
            color={data.cta.bgColor}
            href={data.cta.destination}
            //target={data.cta.newWindow ? "_blank" : ""}
            rel="noreferrer"
          >
            {data.cta.label}
          </ButtonStyled>
        )}
      </RightCol>
    </Main>
  );
}

interface MainProps {
  $isFullScreen?: boolean;
  $isImageLeft?: boolean;
}

const style = "lg:grid lg:grid-cols-12";

const Main = tw.div<MainProps>`
  my-0
  lm:px-0 
  w-full
  px-4
  py-5
  lg:p-10
  lg:flex
  lg:justify-between
  lg:items-center
  lg:w-full
  lg:gap-5
  ${(p) => p.$isFullScreen && "lg:px-60"}
  ${(p) => (p.$isImageLeft ? "flex-row" : "flex-row-reverse")}
`;

const LeftCol = tw.div`
  flex
  justify-start
  items-start
  lg:justify-center
  lg:w-1/3
`;

const StyledImg = tw(Image)`
 
`;

const RightCol = tw.div`
  lg:w-2/3
  lg:ml-5
`;

const ButtonStyled = tw(Button)`
  mt-4
  lg:mt-0
`;
